import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

function LoginButton() {
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const handleLogin = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.error("Error during loginWithRedirect:", error);
    }
  };
  

  return !isAuthenticated ? (
    <Button variant="outlined" color="inherit" onClick={handleLogin}>Log in</Button>
  ) : <></>;


}

export default LoginButton;