import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { Image } from "../models/Image"
import { Result } from "../models/Result"
import { Status } from "../models/Status"
import dayjs from 'dayjs';
import ButtonAppBar from './ButtonAppBar';
import Chip from '@mui/material/Chip';
import { BASE_URL } from '../constants/global';
import Pagination from '@mui/material/Pagination';
import { Link } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Button, CircularProgress } from '@mui/material'; // Import CircularProgress for loader
import { useAuth0 } from '@auth0/auth0-react';

export const AedesTable: React.FC = () => {
  const [images, setAllImages] = React.useState<Image[]>([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState(0);
  const [loading, setLoading] = React.useState(false); // State for loader
  const [error, setError] = React.useState(""); // State for error message
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    document.title = "Aedes | Images";
  }, []);

  useEffect(() => {
    fetchImages();
  }, [page, pageSize, user]);

  const fetchImages = async () => {
    setLoading(true); // Set loading to true while fetching
    try {
      const accessToken = await getAccessTokenSilently();
      const url = `${BASE_URL}/v1/image/user/${user?.email}?page=${page}&size=${pageSize}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch images');
      }

      const data = await response.json();
      setAllImages(data.content);
      setTotalPages(data.totalPages);
      setTotalElements(data.totalElements);
    } catch (error) {
      setError('Error fetching images');
      console.error('Error fetching images:', error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
  };
  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const newSize = event.target.value;
    setPageSize(newSize as number);
    setPage(0);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{paddingTop: "25px"}}>
        <h1>{error}</h1>
        <Button variant="outlined" onClick={fetchImages}>Retry</Button>
      </div>
    );
  }

  if (images.length === 0) {
    return (
      <div style={{paddingTop: "25px"}}>
        <h1>You haven't uploaded any images.</h1>
        <h2>Let's change that.</h2>
        <Button variant="outlined" component={Link} to="/add">Count Mosquito Eggs (OpenCV)</Button><br /><br />
        <Button variant="outlined" component={Link} to="/yolo">Count Mosquito Eggs (YOLO)</Button><br /><br />
        <Button variant="outlined" color="warning" component={Link} to="/">Go home</Button>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: "10px"}}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Image ID</TableCell>
              <TableCell align="right">Date Captured</TableCell>
              <TableCell align="right">Egg Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {images.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <a href={`/image/${row.id}`}>{row.uniqueImageId}</a>
                </TableCell>
                <TableCell align="right">{dayjs(row.pictureDate).format('MM-DD-YYYY')}</TableCell>
                <TableCell align="right">{row.computedEggCount ? row.computedEggCount : <Chip label="no eggs" color="warning" />}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', paddingTop: "20px", justifyContent: 'space-between' }}>
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={handlePageChange}
        />
        {/* <FormControl sx={{ minWidth: 100 }}>
          <InputLabel>Records per page</InputLabel>
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            sx={{ width: '120px' }}
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl> */}
      </div>
    </div>
  );
};

export default AedesTable;
