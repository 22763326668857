import * as React from 'react';
import { useEffect, useState } from 'react';
import ButtonAppBar from '../components/ButtonAppBar';
import { Card, Box, Stack, Typography, Divider, CardContent } from '@mui/material';
import { grey } from '@mui/material/colors';
import Email from '@mui/icons-material/Email';
import { useAuth0 } from '@auth0/auth0-react';
import { BASE_URL } from '../constants/global';

export const Profile: React.FC = () => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [userData, setUserData] = useState({
    affiliation: '',
    email: user?.email || '',
    name: user?.name || '',
    subId: user?.sub || '',
    givenName: user?.givenName || '',
  });
  useEffect(() => {
    if (isAuthenticated && userData.subId) {
      const getUserProfile = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          console.log('Access Token:', accessToken);
          const response = await fetch(`${BASE_URL}/v1/user/profile`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          // Check if the response status is 200
          if (response.ok) {
            // Check if the response body is not empty to determine if the user profile exists
            const userProfile = await response.json();
            if (Object.keys(userProfile).length !== 0) {
              // User profile exists, update the state
              setUserData(userProfile);
            } else {
              // User profile not found, handle accordingly
              console.log('User profile not found');
            }
          } else {
            // Handle other status codes if needed
            console.error('Failed to fetch user profile:', response.status);
          }
        } catch (error) {
          // Handle network or other errors
          console.error('Error fetching user profile:', error);
        }
      };
  
      getUserProfile();
    }
  }, [isAuthenticated, userData.subId, getAccessTokenSilently]);
  
  return (
    <div>
    <ButtonAppBar />
    <Card style={{ display: 'flex', justifyContent: 'center' }}>
      <CardContent>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h5" fontWeight={700} gutterBottom>
            {userData.name}
          </Typography>
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center">
              <Email sx={{ color: grey[500] }} />
              <Typography variant="body2" color="text.secondary">
                {userData.email}
              </Typography>
            </Stack>
            <Typography variant="body1" color="text.secondary">
              {userData.affiliation}
            </Typography>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  </div>
  );
};

export default Profile;
