import Button from '@mui/material/Button';
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import AedesTable from '../components/AedesTable';
import ButtonAppBar from "../components/ButtonAppBar";
import { Fab, List, ListItem, ListItemButton, Popover } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const ViewTable: React.FC = () => {
  useEffect(() => {
    document.title = "ACTT | View"
  }, []);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (link: string) => {
    handleClose();
    // Perform any additional actions here, such as navigating to the selected link
    navigate(link);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="App">
      <ButtonAppBar />
      <AedesTable />
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleClick}
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1000,
        }}
      >
        <AddIcon />
      </Fab>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleMenuItemClick("/yolo")}>
              YOLO
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleMenuItemClick("/add")}>
              OpenCV
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}

export default ViewTable;
