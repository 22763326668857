import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import React, { useEffect, useState } from "react";
import { Alert, Card, CardContent, CardMedia, CircularProgress, Grid, IconButton, InputAdornment, Rating, Stack, Typography } from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import ButtonAppBar from "../components/ButtonAppBar";
import { Controller, useForm } from "react-hook-form";
import axios, { AxiosError } from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../constants/global";
import { YoloImage } from "../components/YoloImage";
import { useGeolocated } from "react-geolocated";
import LocationOn from "@mui/icons-material/LocationOn";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getValue } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import { Image } from "../models/Image";
import packageJson from '../../package.json';
import './AedesForms.css';
import { image, data } from "@tensorflow/tfjs";

export interface routePrams {
  id: string;
}
export const EditAede: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "ACTT | Edit";
  }, []);
  const { user, getAccessTokenSilently } = useAuth0();

  const { register, setValue, reset, getValues, handleSubmit, control, formState: { errors, dirtyFields } } = useForm<Image>();

  const [imageData, setImageData] = useState<Image | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [analyzedImageUrl, setAnalyzedImageUrl] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching data
      setError(""); // Clear error state
  
      try {
        const accessToken = await getAccessTokenSilently();
        const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  
        // Fetch image data
        const imageDataResponse = await fetch(`${BASE_URL}/v1/image/${id}`, config);
        if (!imageDataResponse.ok) {
          throw new Error('Failed to fetch image data');
        }
        const imageData = await imageDataResponse.json();
        setImageData(imageData);
  
        // Populate form fields with API data
        Object.keys(imageData).forEach(key => {
          setValue(key as keyof Image, imageData[key as keyof Image]);
        });

        if (imageData) {
          setSetUpDate(dayjs(imageData.setUpDate));
          setCollectionDate(dayjs(imageData.collectionDate));
          setPictureDate(dayjs(imageData.pictureDate));
          setHatchingDate(dayjs(imageData.hatchingDate));
        }
  
        // Fetch main image
        const mainImageResponse = await fetch(`${BASE_URL}/files/${imageData.fileName}.jpg`, config);
        if (mainImageResponse.ok) {
          const mainImageBlob = await mainImageResponse.blob();
          const mainImageObjectURL = URL.createObjectURL(mainImageBlob);
          setImageUrl(mainImageObjectURL);
        } else {
          console.error('Failed to fetch main image');
        }
  
        // Fetch analyzed image
        const analyzedImageResponse = await fetch(`${BASE_URL}/files/${imageData.fileName}_analyzed.jpg`, config);
        if (analyzedImageResponse.ok) {
          const analyzedImageBlob = await analyzedImageResponse.blob();
          const analyzedImageObjectURL = URL.createObjectURL(analyzedImageBlob);
          setAnalyzedImageUrl(analyzedImageObjectURL);
        } else {
          console.error('Failed to fetch analyzed image');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError("Failed to fetch data. Please try again."); // Set error message
      }
      finally {
        setLoading(false); // Set loading to false when done fetching
      }
    };
  
    fetchData();
  }, [id, getAccessTokenSilently, setValue]);
  
  const [locale, setLocale] = React.useState<string>("en");
  const [setUpDate, setSetUpDate] = React.useState<Dayjs | null>(null);
  const [collectionDate, setCollectionDate] = React.useState<Dayjs | null>(null);
  const [pictureDate, setPictureDate] = React.useState<Dayjs | null>(null);
  const [hatchingDate, setHatchingDate] = React.useState<Dayjs | null>(null);
  const [additionalOptions, setAdditionalOptions] = useState<boolean>(false);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  const populateLocation = (event: any) => {
    setValue("address", coords?.latitude + ", " + coords?.longitude);
  };

  const [rating, setRating] = useState<number | null>(null);

  useEffect(() => {
    // Add event listener to detect leaving the page
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (dirtyFields) {
        event.preventDefault();
        event.returnValue = ""; // Required for Chrome
        return ""; // Required for Firefox
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up the event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dirtyFields]);

  const handleBackToImage = (event: any) => {
    if (Object.keys(dirtyFields).length > 0) {
      event.preventDefault(); // Prevent default link behavior
      alert("You have unsaved changes. Please save or discard them before leaving.");
    } else {
      // Navigate to image if there are no unsaved changes
      navigate("/image/" + id);
    }
  };

  const onSubmit = async (event: any) => {
    // const body = JSON.stringify(formData);
    // event.preventDefault();
    const accessToken = await getAccessTokenSilently();
    // console.log("updatedValues" + JSON.stringify(getValues()));
    // console.log(String(setUpDate));
    setLoading(true); // Set loading to true when submitting form
    setError(""); // Clear error state
    //Post applciation JSON from form values along with the context token. Set the context ids and then post.
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      setValue("authorIdentity", user?.email ? user?.email : "no_email");
      setValue("setUpDate", setUpDate);
      setValue("collectionDate", collectionDate);
      setValue("pictureDate", pictureDate);
      setValue("hatchingDate", hatchingDate);
      const body = getValues();
      // console.log("Values" + JSON.stringify(body));
      const res = axios
        .put(`${BASE_URL}/v1/image/`, body, config)
        .then((res) => {
          // console.log("Resulting data" + res.data);
          if (res.status == 200) {
            console.log("Status is " + res.status);
            reset({}, { keepDirty: false });
            navigate("/images");
          }
        })
        .catch((e) => {
          console.error(e + ":\n" + (e as AxiosError)?.response?.data);
          setError("Failed to submit data. Please try again."); // Set error message
        });
    } catch (e) {
      console.error(e);
    }
    finally {
      setLoading(false); // Set loading to false when done submitting
    }
  };

  const origins = [
    {
      value: "field",
    },
    {
      value: "lab",
    },
  ];
  const types = [
    {
      value: "blank",
    },
    {
      value: "positive",
    },
    {
      value: "negative",
    },
  ];
  const lightConditions = [
    {
      value: "dim",
    },
    {
      value: "light",
    },
  ];
  const photoConditions = [
    {
      value: "indoor",
    },
    {
      value: "outdoor",
    },
  ];
  const paperTypes = [
    {
      value: "seed paper",
    },
    {
      value: "whatman paper",
    },
  ];
  const paperConditions = [
    {
      value: "wet",
    },
    {
      value: "dry",
    },
  ];
  const waterTypes = [
    {
      value: "tap",
    },
    {
      value: "other",
    },
  ];
  const trapTypes = [
    {
      value: "ovicup",
    },
    {
      value: "white remakin",
    },
  ];

  // useEffect(() => {
  //   console.log("Dirty fields:", dirtyFields);
  // }, [dirtyFields]);

  return (
    <div className="App">
      <ButtonAppBar />

      <h1>Edit Image Metadata</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
      {loading && <CircularProgress />}
      {error && <p>{error}</p>}
        <div style={{ margin: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card sx={{ maxWidth: 500, padding: "20px" }}>
                <CardMedia>
                  <img
                    src={imageUrl}
                    alt={"Original Image"}
                    style={{ maxHeight: "500px" }}
                  />
                </CardMedia>
                <Typography variant="body2" color="text.secondary" align="center">
                  Original
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ maxWidth: 500, padding: "20px" }}>
                <CardMedia>
                  <img
                    src={analyzedImageUrl}
                    alt={"Analyzed Image"}
                    style={{ maxHeight: "500px" }}
                  />
                </CardMedia>
                <Typography variant="body2" color="text.secondary" align="center">
                  Analyzed
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* <h2 className="dirtyField">Indicates edited field</h2> */}
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      ></Box>
      {/* <form onSubmit={async () => { handleSubmit(onSubmit);  setTimeout(() => {
            navigate("/images");
         }, 500);}}> */}
      <form className="textLeft" onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={3}
          paddingLeft={10}
          paddingRight={10}
          paddingBottom={10}
        >
          <Controller
            control={control}
            name="siteId"
            defaultValue={imageData ? imageData.siteId : ""}
            render={({ field }) => (
              <TextField
                {...field}
                id="siteId"
                {...register("siteId", { required: 'Site Id is required' })}
                label="Site Id"
                variant="outlined"
                required
                error={!!errors.siteId}
                helperText={errors.siteId?.message}
                className={dirtyFields?.siteId ? "dirtyField" : ""}
              />
            )}
          />

          <Controller
            control={control}
            name="descriptorId"
            defaultValue={imageData ? Number(imageData.descriptorId) : undefined}
            render={({ field }) => (
              <TextField
                id="descriptorId"
                label="Descriptor Id"
                type="number"
                variant="outlined"
                {...register('descriptorId', {
                  required: 'Descriptor Id is required',
                  min: {
                    value: 1,
                    message: 'Descriptor Id must be a positive value',
                  },
                })}
                required
                error={!!errors.descriptorId}
                helperText={errors.descriptorId?.message}
                InputLabelProps={{
                  shrink: true, // Ensure label is always displayed above the input field
                }}
                className={dirtyFields?.descriptorId ? "dirtyField" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="weekId"
            defaultValue={imageData ? Number(imageData.weekId) : undefined}
            render={({ field }) => (
              <TextField
                id="weekId"
                label="Week Id"
                type="number"
                variant="outlined"
                {...register("weekId")}
                InputLabelProps={{
                  shrink: true, // Ensure label is always displayed above the input field
                }}
                className={dirtyFields?.weekId ? "dirtyField" : ""}
              />
            )}

          />

          <Controller
            control={control}
            name="sampleName"

            defaultValue={imageData ? imageData.sampleName : ""}
            render={({ field }) => (
              <TextField
                id="sampleName"
                label="Sample Name"
                variant="outlined"
                {...field}
                {...register("sampleName")}
                className={dirtyFields?.sampleName ? "dirtyField" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="address"
            defaultValue={imageData ? imageData.address : ""}
            render={({ field }) => (
              <TextField
                id="address"
                label="Address"
                variant="outlined"
                {...field}
                {...register("address", { required: true })}
                className={dirtyFields?.address ? "dirtyField" : ""}
                required
                error={!!errors.address}
                helperText={errors.address?.message}
                InputProps={{
                  endAdornment:
                    isGeolocationEnabled && isGeolocationAvailable ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Populate Location"
                          onClick={populateLocation}
                        >
                          <LocationOn />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      ""
                    ),
                }}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name="setUpDate"
              render={({ field }) => (
                <DesktopDatePicker
                  className={dirtyFields?.setUpDate ? "dirtyField" : ""}

                  label="Setup Date"
                  // inputFormat="MM/dd/yyyy"
                  value={setUpDate}
                  onChange={(date) => date && setSetUpDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
                // <DesktopDatePicker
                //   label="Setup Date"
                //   inputFormat="MM/dd/yyyy"
                //   value={setUpDate}
                //   onChange={(date) => date && setSetUpDate(date)}
                //   renderInput={(params) => <TextField {...params} />}
                // />
              )}
            />
            <Controller
              control={control}
              name="collectionDate"
              render={({ field }) => (
                <DesktopDatePicker
                  className={dirtyFields?.collectionDate ? "dirtyField" : ""}
                  label="Collection Date"
                  value={collectionDate}
                  onChange={(date) => date && setCollectionDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
            <Controller
              control={control}
              name="pictureDate"
              render={({ field }) => (
                <DesktopDatePicker
                  className={dirtyFields?.collectionDate ? "dirtyField" : ""}
                  label="Picture Date"
                  value={pictureDate}
                  onChange={(date) => date && setPictureDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
          {/* setUpDate
     collectionDate
     pictureDate */}

          {/* Hatching Date */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name="hatchingDate"
              render={({ field }) => (
                <DesktopDatePicker
                  className={dirtyFields?.hatchingDate ? "dirtyField" : ""}
                  label="Hatching Date"
                  value={hatchingDate}
                  onChange={(date) => date && setHatchingDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>

          <Controller
            control={control}
            name="deployTime"
            render={({ field }) => (
              <TextField
                id="deployTime"
                label="Deploy Time (days)"
                type="number"
                variant="outlined"
                defaultValue={imageData ? imageData.deployTime : ""}
                className={dirtyFields?.deployTime ? "dirtyField" : ""}
                {...register('deployTime', {
                  required: 'Deploy Time is required',
                  min: {
                    value: 1,
                    message: 'Deploy Time Id must be a positive value',
                  },
                })} required
                error={!!errors.deployTime}
                helperText={errors.deployTime?.message}
                InputLabelProps={{
                  shrink: true, // Ensure label is always displayed above the input field
                }}
              />
            )}
          />

          {additionalOptions ? (
            <>
              <Controller
                control={control}
                name="manualEggCount"
                render={({ field }) => (
                  <TextField
                    id="manualEggCount"
                    label="Manual Egg Count"
                    type="number"
                    variant="outlined"
                    defaultValue={imageData ? imageData.manualEggCount : ""}
                    className={dirtyFields?.manualEggCount ? "dirtyField" : ""}
                    {...register('manualEggCount', {
                      min: {
                        value: 0,
                        message: 'Manual Egg Count Time Id must be a positive value',
                      },

                    }
                    )}
                    InputLabelProps={{
                      shrink: true, // Ensure label is always displayed above the input field
                    }} />
                )}
              />
              <Controller
                control={control}
                name="species"
                render={({ field }) => (
                  <TextField
                    id="species"
                    label="Species"
                    variant="outlined"
                    defaultValue={imageData ? imageData.species : ""}
                    className={dirtyFields?.species ? "dirtyField" : ""}
                    {...register("species")}
                  />
                )}
              />
              <Controller
                control={control}
                name="origin"
                render={({ field }) => (
                  <TextField
                    id="origin"
                    select
                    label="Origin"
                    placeholder="Origin"
                    defaultValue={imageData ? imageData.origin : ""}
                    // value={origin}
                    {...register("origin", { required: "Origin is required" })}
                    required
                    error={!!errors.origin}
                    helperText={errors.origin?.message}
                    className={dirtyFields?.origin ? "dirtyField" : ""}

                  // onChange={handleChange}
                  >
                    {origins.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="lightCondition"
                render={({ field }) => (
                  <TextField
                    id="lightCondition"
                    select
                    label="Light Condition"
                    defaultValue={imageData ? imageData.lightCondition : ""}
                    {...register("lightCondition", { required: "Light Condition is required" })}
                    required
                    error={!!errors.lightCondition}
                    helperText={errors.lightCondition?.message}
                    className={dirtyFields?.lightCondition ? "dirtyField" : ""}

                  // onChange={handleChange}
                  >
                    {lightConditions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="photoCondition"
                render={({ field }) => (
                  <TextField
                    id="photoCondition"
                    select
                    label="Photo Condition"
                    defaultValue={imageData ? imageData.photoCondition : ""}
                    {...register("photoCondition", { required: "Photo Condition is required" })}
                    required
                    error={!!errors.photoCondition}
                    helperText={errors.photoCondition?.message}
                    className={dirtyFields?.photoCondition ? "dirtyField" : ""}

                  // onChange={handleChange}
                  >
                    {photoConditions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="paperType"
                render={({ field }) => (
                  <TextField
                    id="paperType"
                    select
                    label="Paper Type"
                    defaultValue={imageData ? imageData.paperType : ""}
                    {...register("paperType", { required: "Paper Type is required" })}
                    required
                    error={!!errors.paperType}
                    helperText={errors.paperType?.message}
                    className={dirtyFields?.paperType ? "dirtyField" : ""}

                  // onChange={handleChange}
                  >
                    {paperTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="paperColor"
                render={({ field }) => (
                  <TextField
                    id="paperColor"
                    label="Paper Color"
                    variant="outlined"
                    defaultValue={imageData ? imageData.paperColor : ""}
                    {...register("paperColor", { required: "Paper Color is required" })}
                    required
                    error={!!errors.paperColor}
                    helperText={errors.paperColor?.message}
                    className={dirtyFields?.paperColor ? "dirtyField" : ""}
                  />
                )}
              />
              <Controller
                control={control}
                name="paperCondition"
                render={({ field }) => (
                  <TextField
                    id="paperCondition"
                    select
                    label="Paper Condition"
                    defaultValue={imageData ? imageData.paperCondition : ""}
                    {...register("paperCondition", { required: "Paper Condition is required" })}
                    required
                    error={!!errors.paperCondition}
                    helperText={errors.paperCondition?.message}
                    className={dirtyFields?.paperCondition ? "dirtyField" : ""}

                  // onChange={handleChange}
                  >
                    {paperConditions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="trapType"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    id="trapType"
                    select
                    label="Trap Type"
                    defaultValue={imageData ? imageData.trapType : ""}
                    {...register("trapType", { required: "Trap Type is required" })}
                    required
                    error={!!errors.trapType}
                    helperText={errors.trapType?.message}
                    className={dirtyFields?.trapType ? "dirtyField" : ""}

                  // onChange={handleChange}
                  >
                    {trapTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="waterType"
                render={({ field }) => (
                  <TextField
                    id="waterType"
                    select
                    label="Water Type"
                    defaultValue={imageData ? imageData.waterType : ""}
                    {...register("waterType", { required: "Water Type is required" })}
                    required
                    error={!!errors.waterType}
                    helperText={errors.waterType?.message}
                    className={dirtyFields?.waterType ? "dirtyField" : ""}

                  // onChange={handleChange}
                  >
                    {waterTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="type"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    id="type"
                    select
                    label="Type"
                    defaultValue={imageData ? imageData.type : ""}
                    {...register("type", { required: "Type is required" })}
                    required
                    error={!!errors.type}
                    helperText={errors.type?.message}
                    className={dirtyFields?.type ? "dirtyField" : ""}

                  // onChange={handleChange}
                  >
                    {types.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="heightPhoto"
                render={({ field }) => (
                  <TextField
                    id="heightPhoto"
                    label="Photo Height"
                    type="number"
                    variant="outlined"
                    defaultValue={imageData ? imageData.heightPhoto : ""}
                    className={dirtyFields?.heightPhoto ? "dirtyField" : ""}
                    {...register('heightPhoto', {
                      min: {
                        value: 0,
                        message: 'Photo height must be a positive value',
                      },
                    })}
                    InputLabelProps={{
                      shrink: true, // Ensure label is always displayed above the input field
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="stand"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    id="stand"
                    label="Stand"
                    variant="outlined"
                    defaultValue={imageData ? imageData.stand : ""}
                    className={dirtyFields?.stand ? "dirtyField" : ""}
                    {...register("stand")}
                  />
                )}
              />
              <Controller
                control={control}
                name="anglePhoto"
                render={({ field }) => (
                  <TextField
                    id="anglePhoto"
                    label="Photo Angle"
                    type="number"
                    variant="outlined"
                    defaultValue={imageData ? imageData.anglePhoto : ""}
                    className={dirtyFields?.anglePhoto ? "dirtyField" : ""}
                    {...register('anglePhoto', {
                      min: {
                        value: 0,
                        message: 'The photo angle must be 0-360 degrees',
                      },
                      max: {
                        value: 360,
                        message: 'The photo angle must be 0-360 degrees',
                      }
                    })}
                    InputLabelProps={{
                      shrink: true, // Ensure label is always displayed above the input field
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="device"
                render={({ field }) => (
                  <TextField
                    id="device"
                    label="Device"
                    variant="outlined"
                    defaultValue={imageData ? imageData.device : ""}
                    className={dirtyFields?.device ? "dirtyField" : ""}
                    {...register("device")}
                  />
                )}
              />
              <Controller
                control={control}
                name="resolution"
                render={({ field }) => (
                  <TextField
                    id="resolution"
                    label="Resolution"
                    variant="outlined"
                    defaultValue={imageData ? imageData.resolution : ""}
                    className={dirtyFields?.resolution ? "dirtyField" : ""}
                    {...register("resolution")}
                  />
                )}
              />
              <Controller
                control={control}
                name="note"
                render={({ field }) => (
                  <TextField
                    id="note"
                    label="Note"
                    variant="outlined"
                    multiline
                    rows={4}
                    defaultValue={imageData ? imageData.note : ""}
                    className={dirtyFields?.note ? "dirtyField" : ""}
                    {...register("note")}
                  />
                )}
              />
              <Button onClick={() => setAdditionalOptions(false)}>
                Hide Options
              </Button>{" "}
            </>
          ) : (
            <Button onClick={() => setAdditionalOptions(true)}>
              More Options
            </Button>
          )}
          <Button type="submit" variant="outlined" color="success">
            Save edits
          </Button>
          <div className="backListButton">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBackToImage}
            >
              Back to image
            </Button>
          </div>
        </Stack>
      </form>
    </div>
  );
};

export default EditAede;
