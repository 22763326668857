import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BASE_URL } from '../constants/global';
import { Select, MenuItem, Button, FormControl, InputLabel, Container, CircularProgress } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

export const ProfileForm: React.FC = () => {
  const universityOptions = [
    'University of South Carolina',
    'Baylor University',
    'Florida International University',
    'National Institute of Health',
  ];

  const { user, isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading, error } = useAuth0();

  const [formData, setFormData] = useState({
    affiliation: '',
    email: user?.email || '',
    name: user?.name || '',
    subId: user?.sub || '',
    givenName: user?.given_name || '',
  });

  const [profileLoading, setProfileLoading] = useState(true); // Separate loading state for profile check
  const [formLoading, setFormLoading] = useState(false); // Separate loading state for form submission
  const [profileExists, setProfileExists] = useState<boolean | null>(null); // Track if the profile exists
  const [fetchError, setFetchError] = useState<string | null>(null); // State for fetch errors

  // Fetch user profile
  const fetchUser = async (retryCount = 3) => {
    if (!isAuthenticated) {
      console.log("User not authenticated");
      setProfileLoading(false);
      return;
    }

    let timeoutId;

    try {
      console.log("Fetching access token");
      const accessToken = await getAccessTokenSilently();

      const controller = new AbortController();
      const signal = controller.signal;

      timeoutId = setTimeout(() => {
        controller.abort();
      }, 5000); // 5 seconds timeout for the request

      console.log("Fetching user profile");
      const response = await fetch(`${BASE_URL}/v1/user/profile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal,
      });

      clearTimeout(timeoutId);

      if (response.ok) {
        if (response.headers.get('content-length') === '0') {
          console.log("Empty response body");
          setProfileExists(false);
        } else {
          const contentType = response.headers.get('content-type');
          if (contentType?.includes('application/json')) {
            const textResponse = await response.text();
            try {
              if (textResponse.trim() === '') {
                console.log("Profile does not exist");
                setProfileExists(false);
              } else {
                const data = JSON.parse(textResponse);
                console.log("Profile data:", data);
                if (Object.keys(data).length !== 0) {
                  setProfileExists(true); // If profile exists
                  window.location.href = '/'; // Redirect if profile exists
                } else {
                  console.log("Profile does not exist");
                  setProfileExists(false);
                }
              }
            } catch (jsonError) {
              console.error("Error parsing JSON:", jsonError);
              setFetchError('Error parsing user profile data.');
            }
          } else {
            console.error("Unexpected content type:", contentType);
            setFetchError('Unexpected content type received from user profile API.');
          }
        }
      } else {
        console.error("Error fetching user profile:", response.statusText);
        setFetchError('Error fetching user profile');
      }
    } catch (error: unknown) {
      clearTimeout(timeoutId);
      console.error("Error caught:", error);

      if (error instanceof Error) {
        if (error.name === 'AbortError') {
          console.warn('Request timed out. Retrying...');
          if (retryCount > 0) {
            setTimeout(() => {
              fetchUser(retryCount - 1); // Retry after 1 second
            }, 1000);
          } else {
            console.error('Max retries reached. Could not fetch user profile.');
            setFetchError('Max retries reached. Could not fetch user profile.');
          }
        } else {
          setFetchError('Error fetching user profile: ' + error.message);
          console.error('Error fetching user profile:', error.message);
        }
      } else {
        setFetchError('An unknown error occurred.');
        console.error('An unknown error occurred.');
      }
    } finally {
      console.log("Finished fetching user profile");
      setProfileLoading(false); // Set loading to false
    }
  };

  useEffect(() => {
    fetchUser(); // Call fetchUser when the component mounts
  }, [isAuthenticated, getAccessTokenSilently]);

  // Handle form field change
  const handleChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value,
    });
  };

  const handleLogin = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.error("Error during loginWithRedirect:", error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setFormLoading(true); // Set form loading to true
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${BASE_URL}/v1/user/register/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Profile created successfully');
        window.location.href = '/';
      } else {
        console.error('Error creating profile:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating profile:', error);
    } finally {
      setFormLoading(false); // Set form loading to false
    }
  };

  // Show loading spinner if profile check is in progress
  if (isLoading || profileLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  // Show fetch error message
  if (fetchError) {
    return (
      <Container maxWidth="sm">
        <h2>Error: {fetchError}</h2>
        <Button variant="outlined" color="inherit" onClick={handleLogin}>
          Retry
        </Button>
      </Container>
    );
  }

  // Show email verification message if the user's email is not verified
  if (!isAuthenticated && !user?.email_verified) {
    return (
      <Container maxWidth="sm">
        <h2>Please check your email to verify your account</h2>
        <Button variant="outlined" color="inherit" onClick={handleLogin}>
          Check verification
        </Button>
        <h3>Not there? Try checking your spam folder.</h3>
      </Container>
    );
  }

  // Show profile form if the profile doesn't exist and the user's email is verified
  if (profileExists === false && user?.email_verified) {
    return (
      <Container maxWidth="sm">
        <div className="profile-form">
          <form onSubmit={handleSubmit}>
            <h3>Please complete your profile</h3>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="affiliation-label">Affiliation</InputLabel>
              <Select
                labelId="affiliation-label"
                id="affiliation"
                name="affiliation"
                value={formData.affiliation}
                onChange={handleChange}
                label="Affiliation"
              >
                <MenuItem value="">
                  <em>Select University</em>
                </MenuItem>
                {universityOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              style={{ marginTop: '20px' }}
              disabled={formLoading} // Disable button while loading
            >
              {formLoading ? <CircularProgress size={24} /> : 'Create Profile'}
            </Button>
          </form>
        </div>
      </Container>
    );
  }

  // If no conditions match, return null (should not reach here)
  return null;
};
