/**
 * Class to handle webcam
 */
export class Webcam {
  /**
   * Open webcam and stream it through video tag.
   * @param {HTMLVideoElement} videoRef video tag reference
   */
  open = (videoRef) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "environment",
          },
        })
        .then((stream) => {
          videoRef.srcObject = stream;
        });
    } else alert("Can't open Webcam!");
  };

  /**
   * Capture a frame from the webcam and return it as a data URL.
   * @param {HTMLVideoElement} videoRef video tag reference
   * @returns {Promise<string | null>} A Promise that resolves to the captured frame as a data URL
   */
  captureFrame = async (videoRef) => {
    if (!videoRef) {
      return null;
    }

    const canvas = document.createElement('canvas');
    canvas.width = videoRef.videoWidth;
    canvas.height = videoRef.videoHeight;

    const context = canvas.getContext('2d');
    context.drawImage(videoRef, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL('image/jpeg'); // You can choose the desired format
  };
  
  /**
   * Close opened webcam.
   * @param {HTMLVideoElement} videoRef video tag reference
   */
  close = (videoRef) => {
    if (videoRef.srcObject) {
      videoRef.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      videoRef.srcObject = null;
    } else alert("Please open Webcam first!");
  };
}
