import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";
import { Loading } from "./Loading";


interface AuthenticationGuardProps {
  component: ComponentType<any>;
}

const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({ component: Component }) => {
  const AuthenticatedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => (
      <div className="page-layout">
        <Loading />
      </div>
    ),
  });

  return <AuthenticatedComponent />;
};

export default AuthenticationGuard;