
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import { padding } from '@mui/system';

import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import ButtonAppBar from "../components/ButtonAppBar";
import SponsorImages from '../components/SponsorImages';
import SurveyButton from '../components/SurveyButton';

export const Home: React.FC = () => {
  useEffect(() => {
    document.title = "ACTT Study"
  }, []);
  const {
    isAuthenticated,
  } = useAuth0();

  return (
    <div className="App">
      <ButtonAppBar />
      <div style={{ padding: 20 }}>
        <img src="/logo.png" alt="ACTT Logo" style={{ maxWidth: 200 }} />
        {/* <h1>Welcome to ACTT Study Beta v2!</h1>
     <h2>About ACTT Study</h2> */}
        <p>Mosquito-borne diseases are a global concern that impact public health, tourism, and natural disaster recovery efforts. With no preventative vaccines or effective therapeutic option available for most mosquito-borne diseases, vector control efforts are the only combative option. These efforts are often limited by financial constraint and insecticide resistance concern. The ACTT study aims to integrate cutting-edge mosquito collection traps, innovative new methods for assessing mosquito risk, and novel remote sensing and machine learning workflows to guide local vector operations and direct abatement efforts in a cost-efficient manner.</p>

        {isAuthenticated ? <><Button variant="outlined" color="success" component={Link} to="/images">View your records</Button><br /><br /><Button variant="outlined" color="info" component={Link} to="/profile">View Profile</Button>
          <br /><br /><Button variant="outlined" component={Link} to="/add">Count Mosquito Eggs (OpenCV)</Button><br /><br />
          <Button variant="outlined" component={Link} to="/yolo">Count Mosquito Eggs (YOLO) </Button></> : <Button variant="outlined" component={Link} to="/playground">Try our algorithm</Button>

        }
        <h2>Supported by</h2>
      </div>

      <SponsorImages />

      <h4>We value your feedback!</h4>
      <SurveyButton />
    </div>
  );
}

export default Home;