import { Button } from "@mui/material";
import { useState, useRef } from "react";
import { Webcam } from "../utils/webcam";

const ButtonHandler = ({ canvasRef, cameraRef, videoRef, setComputedEggCount, imageSrcState, imagesSubmitted }) => {
  const [imageSrc, setImageSrc] = imageSrcState;
  const [streaming, setStreaming] = useState(null); // streaming state
  const inputImageRef = useRef(null); // video input reference
  const inputVideoRef = useRef(null); // video input reference
  const webcam = new Webcam(); // webcam handler

  // closing image
  const closeImage = () => {
    URL.revokeObjectURL(imageSrc); // revoke url
    setImageSrc(null);
    setStreaming(null); // set streaming to null
    setComputedEggCount(null); // set computed egg count to null
    inputImageRef.current.value = ""; // reset input image
    canvasRef.current.style.display = "none"; // hide image
  };

  // closing video streaming
  const closeVideo = () => {
    const url = videoRef.current.src;
    videoRef.current.src = ""; // restore video source
    URL.revokeObjectURL(url); // revoke url

    setStreaming(null); // set streaming to null
    inputVideoRef.current.value = ""; // reset input video
    videoRef.current.style.display = "none"; // hide video
  };

  return (
    <div className="btn-container">
      {/* Image Handler */}
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => {
          const url = URL.createObjectURL(e.target.files[0]); // create blob url
          setImageSrc(url); // set video source
          canvasRef.current.style.display = "block"; // show video
          setStreaming("image"); // set streaming to video
        }}
        ref={inputImageRef}
      />
      {imagesSubmitted ? "" :
        <Button variant="outlined"
          onClick={() => {
            // if not streaming
            if (streaming === null) inputImageRef.current.click();
            // closing image streaming
            else if (streaming === "image") closeImage();
            else alert(`Can't handle more than 1 stream\nCurrently streaming : ${streaming}`); // if streaming video or webcam
          }}
        >
          {streaming === "image" ? "Clear" : "Select"} Image
        </Button>}


    </div>
  );
};

export default ButtonHandler;
