import React, { useState, useEffect, useRef } from "react";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl";
import Loader from "../components/loader";
import ButtonAppBar from "../components/ButtonAppBar";
import { renderBoxes } from "../utils/renderBox"; // Import renderBoxes function
import "../styles/yolo.css";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { BASE_URL } from "../constants/global";
import axios, { AxiosError } from "axios";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { useAuth0 } from "@auth0/auth0-react";

type MobileYoloImageProps = {
    capturedImage: string | null,
    onImageCapture: (imageData: string | null) => void;
    setFileName: (arg: string) => void,
    computedEggCount: number | null,
    setComputedEggCount: (arg: number) => void,
    imagesSubmitted: boolean,
    setImagesSubmitted: (arg: boolean) => void;

};

/**
 * Preprocess image / frame before forwarded into the model
 * @param {HTMLVideoElement|HTMLImageElement} source
 * @param {Number} modelWidth
 * @param {Number} modelHeight
 * @returns input tensor, xRatio and yRatio
 */
const preprocess = async (src: string, modelWidth: number, modelHeight: number) => {
    const image = new Image();
    image.src = src;
    return await new Promise((resolve) => {
        image.onload = function () {
            const input = tf.tidy(() => {
                const img = tf.browser.fromPixels(image);

                // padding image to square => [n, m] to [n, n], n > m
                const imgPadded = img;

                return tf.image
                    .resizeBilinear(imgPadded, [modelWidth, modelHeight]) // resize frame
                    .div(255.0) // normalize
                    .expandDims(0); // add batch
            });
            resolve(input);
        };
    });
};

/**
 * Function to detect image.
 * @param {string} imageSrc image src
 * @param {tf.GraphModel} model loaded YOLOv5 tensorflow.js model
 * @param {Number} classThreshold class threshold
 * @param {HTMLCanvasElement} canvasRef canvas reference
 */
const detectImage = async (imageSrc: string | null, model: any, classThreshold: any, canvasRef: HTMLCanvasElement | null) => {
    if (!imageSrc || !model || !canvasRef) {
        console.error("Invalid arguments.");
        return 0;
    }

    const [modelWidth, modelHeight] = model.inputShape.slice(1, 3);
    tf.engine().startScope();

    let noEggs = 0;

    try {
        const input = await preprocess(imageSrc, modelWidth, modelHeight);
        const res = await model.net.executeAsync(input);
        const [boxes, scores] = res.slice(0, 3);
        const boxes_data = boxes.dataSync();
        const scores_data = scores.dataSync();

        renderBoxes(canvasRef, classThreshold, boxes_data, scores_data);

        for (let j = 0; j < scores_data.length; j++) {
            if (scores_data[j] > 0) {
                noEggs = noEggs + 1;
            }
        }

        tf.dispose([input, boxes, scores]);
    } catch (error) {
        console.error("Error executing model:", error);
        throw error; // Re-throw the error to handle it in the component
    } finally {
        tf.engine().endScope();
    }

    return noEggs;
};
export const MobileYolo: React.FC<MobileYoloImageProps> = ({ capturedImage,
    computedEggCount,
    setComputedEggCount,
    imagesSubmitted,
    setImagesSubmitted,
    onImageCapture,
    setFileName }) => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [imageAnalyzed, setImageAnalyzed] = useState<boolean>(false);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [loading, setLoading] = useState({ loading: true, progress: 0 });
    const [model, setModel] = useState({
        net: null,
        inputShape: [1, 0, 0, 3],
    });
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [modelLoaded, setModelLoaded] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const modelName = "yolov5n";
    const classThreshold = 0.2;
    const {
        getValues } = useForm({
            defaultValues: {

            }
        });
    function canvasToBlob(canvas: HTMLCanvasElement, type?: string, quality?: number): Promise<Blob> {
        return new Promise((resolve) => {
            if (canvas.toBlob) {
                canvas.toBlob((blob) => {
                    resolve(blob!);
                }, type, quality);
            } else {
                const dataURL = canvas.toDataURL(type, quality);
                const byteString = atob(dataURL.split(',')[1]);
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const uint8Array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < byteString.length; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                }
                resolve(new Blob([arrayBuffer], { type }));
            }
        });
    }
    async function imageToBlob(image: HTMLImageElement): Promise<Blob> {
        // Ensure image is completely loaded
        if (!image.complete) {
            await new Promise(resolve => {
                image.onload = resolve;
            });
        }

        const canvas = document.createElement('canvas');
        // Use width and height to get the displayed size of the image
        canvas.width = image.width;
        canvas.height = image.height;

        const context = canvas.getContext('2d');
        context?.drawImage(image, 0, 0, image.width, image.height);

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob!);
            }, 'image/jpeg');
        });
    }

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setIsUploading(true); // Set uploading to true at the beginning
        let canvas = canvasRef.current;
        const accessToken = await getAccessTokenSilently();
        if (canvas && imageSrc) {
            console.log("updatedValues" + getValues());
            
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                        
                    },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        // console.log(percentCompleted)
                        setUploadProgress(percentCompleted);
                        // console.log("Upload Progress: " + uploadProgress)
                    }
                };
                let localUUID = uuidv4();
                const dirtyFormPush = new FormData();
                setComputedEggCount(computedEggCount ? computedEggCount : 0);
                canvasToBlob(canvas, 'image/jpeg').then(async (blob) => {
                    if (blob) {
                        setFileName(localUUID);
                        console.log('UUID:' + localUUID + " | File Name: " + localUUID + "_analyzed.jpg");
                        dirtyFormPush.append("file", blob, `${localUUID}_analyzed.jpg`);
                        const res: any = await axios.post(
                            `${BASE_URL}/files/`,
                            dirtyFormPush,
                            config
                        ).then(res => {
                            console.log("Resulting data" + res.data);
                            if (res.status == 200) {

                                console.log("Analysis: Status is " + res.status);
                            }
                        }).catch(e => {
                            console.error(e + ":\n" + (e as AxiosError)?.response?.data);
                            console.log(dirtyFormPush);
                            dirtyFormPush.forEach((value, key) => {
                                console.log("key %s: value %s", key, value);
                            });
                        });

                        // For original image
                        const img = new Image();
                        img.src = imageSrc;
                        imageToBlob(img).then(async (blob) => {
                            const originalFormPush = new FormData();
                            originalFormPush.append("file", blob, `${localUUID}.jpg`);
                            await axios.post(
                                `${BASE_URL}/files/`,
                                originalFormPush,
                                config
                            ).then(res => {
                                console.log("Original Image: Resulting data" + res.data);
                                if (res.status == 200) {
                                    console.log("Original Image: Status is " + res.status);
                                    setImagesSubmitted(true);
                                    setIsUploading(false); // Set uploading to false when the upload is complete
                                }
                            }).catch(e => {
                                console.error(e + ":\n" + (e as AxiosError)?.response?.data);
                                console.log(originalFormPush)
                                setIsUploading(false); // Set uploading to false if an error occurred
                                originalFormPush.forEach((value, key) => {
                                    console.log("key %s: value %s", key, value);
                                })
                            });
                        });
                    }
                });
            } catch (e) {
                console.error(e);
            }
        }
    };

    const processImage = async () => {
        if (!imageSrc || !model.net) {
            console.error("Model is not loaded or image source is missing.");
            setError("Model is not loaded or image source is missing.");
            return;
        }

        try {
            const eggCount = await detectImage(imageSrc, model as any, classThreshold, canvasRef.current!);
            setComputedEggCount(eggCount);
            setImageAnalyzed(true);
            setError(null); // Clear any previous errors
        } catch (error) {
            console.error("Error processing image:", error);
            setError(`Error processing image: ${(error as Error).message || (error as Error).toString()}`);
        }
    };



    const loadModelAndProcessImage = async () => {
        try {
            const yolov5 = await tf.loadGraphModel(
                `../model.json`,
                {
                    onProgress: (fractions) => {
                        console.log(fractions);
                        setLoading({ loading: true, progress: fractions });
                        if (fractions === 1) {
                            console.error("Model loading completed.");
                        }
                    },
                }
            );

            const dummyInput = tf.ones(yolov5.inputs[0].shape!);
            const warmupResult = await yolov5.executeAsync(dummyInput);
            tf.dispose(warmupResult);
            tf.dispose(dummyInput);

            setLoading({ loading: false, progress: 1 });
            setModel({
                net: yolov5 as any,
                inputShape: yolov5.inputs[0].shape!,
            });
            setError(null); // Clear any previous errors
            setModelLoaded(true); // Mark the model as loaded
        } catch (error) {
            console.error("Error loading YOLO model:", error);
            setError(`Error loading YOLO model: ${(error as Error).message || (error as Error).toString()}`);
        }
    };

    useEffect(() => {
        if (!model.net) {
            loadModelAndProcessImage();
        }
    }, [model.net]);

    useEffect(() => {
        if (capturedImage) {
            const ctx = canvasRef.current!.getContext("2d");

            if (ctx) {
                canvasRef.current!.style.backgroundColor = "red";
                const image = new Image();

                // Set the src attribute of the image to the capturedImage URL
                image.src = capturedImage;

                image.onload = function () {
                    // Ensure that the canvas dimensions match the image dimensions
                    canvasRef.current!.width = image.width;
                    canvasRef.current!.height = image.height;

                    // Draw the loaded image onto the canvas
                    ctx.drawImage(image, 0, 0, canvasRef.current!.width, canvasRef.current!.height);

                    // Set the image source here
                    setImageSrc(capturedImage);

                    // Process the image only when the image source is set and the model is loaded
                    if (modelLoaded) {
                        processImage();
                    }
                };
            }
        }
    }, [capturedImage, modelLoaded]);

    return (
        <div className="Aedes-view">

            <canvas ref={canvasRef} />
            {loading.loading && <Loader>Loading model... {(loading.progress * 100).toFixed(2)}%</Loader>}
            {error ? (
                <div className="error-message">{error}</div>
            ) : (
                <div>
                    {computedEggCount != null ? <h1 className="text-center">{"Egg Count: " + computedEggCount}</h1> : ""}
                    {isUploading && 
                    <div className="center-content"><CircularProgressWithLabel value={uploadProgress} />
            <p>Uploading your images</p>
                    </div>}
                    {!imagesSubmitted && imageAnalyzed && computedEggCount != null ? (
                        <div>
                            <Button color="primary" variant="outlined" style={{ margin: "10px" }} onClick={onSubmit}>
                                Upload Image
                            </Button>
                            <Button color="warning" variant="outlined" onClick={() => { onImageCapture(null) }}>
                                Redo Image
                            </Button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            )}
        </div>
    );
};

export default MobileYolo;