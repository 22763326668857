import React, { useState, useEffect, useRef } from "react";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl"; // set backend to webgl
import Loader from "../components/loader";
import ButtonHandler from "../components/btn-handler";
import { detectImage } from "../utils/detect";
import "../styles/yolo.css";
import ButtonAppBar from "../components/ButtonAppBar";
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form'
import { toBlob } from "../components/imgPreview"
import { Button, LinearProgress } from "@mui/material";
import { BASE_URL } from "../constants/global";
import axios, { AxiosError } from "axios";
import { model } from "@tensorflow/tfjs";

import {CircularProgressWithLabel} from "./CircularProgressWithLabel";
import { useAuth0 } from "@auth0/auth0-react";

type fileNameProps = {
  setFileName: (arg: string) => void,
  setComputedEggCount: (arg: number) => void
}
export const YoloImage: React.FC<fileNameProps> = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [imagesSubmitted, setImagesSubmitted] = useState<boolean>(false);
  const [imageAnalyzed, setImageAnalyzed] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState(false);
  const [computedEggCount, setComputedEggCount] = useState<number>();
  const [uploadProgress, setUploadProgress] = useState(0);

  const [loading, setLoading] = useState({ loading: true, progress: 0 }); // loading state
  const [model, setModel] = useState({
    net: null,
    inputShape: [1, 0, 0, 3],
  }); // init model & input shape
  const [imageSrc, setImageSrc] = useState(null);

  // references
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // model configs
  const modelName = "yolov5n";
  const classThreshold = 0.2;

  const processImage = async () => {
    // Wait for the image to fully load
    await new Promise(r => setTimeout(r, 0));

    let eggCount = await detectImage(imageSrc!, model as any, classThreshold, canvasRef.current!);
    setComputedEggCount(eggCount);
    setImageAnalyzed(true);
  }
  useEffect(() => {
    document.title = "ACTT | YOLO Playground";
    tf.ready().then(async () => {
      const yolov5 = await tf.loadGraphModel(
        `../model.json`,
        {
          onProgress: (fractions) => {
            setLoading({ loading: true, progress: fractions }); // set loading fractions
          },
        }
      ); // load model

      // warming up model
      const dummyInput = tf.ones(yolov5.inputs[0].shape!);
      const warmupResult = await yolov5.executeAsync(dummyInput);
      tf.dispose(warmupResult); // cleanup memory
      tf.dispose(dummyInput); // cleanup memory

      setLoading({ loading: false, progress: 1 });
      setModel({
        net: yolov5 as any,
        inputShape: yolov5.inputs[0].shape!,
      }); // set model & input shape
    });
  }, []);
  const {
    getValues } = useForm({
      defaultValues: {

      }
    });

  function canvasToBlob(canvas: HTMLCanvasElement, type?: string, quality?: number): Promise<Blob> {
    return new Promise((resolve) => {
      if (canvas.toBlob) {
        canvas.toBlob((blob) => {
          resolve(blob!);
        }, type, quality);
      } else {
        const dataURL = canvas.toDataURL(type, quality);
        const byteString = atob(dataURL.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }
        resolve(new Blob([arrayBuffer], { type }));
      }
    });
  }
  async function imageToBlob(image: HTMLImageElement): Promise<Blob> {
    // Ensure image is completely loaded
    if (!image.complete) {
      await new Promise(resolve => {
        image.onload = resolve;
      });
    }

    const canvas = document.createElement('canvas');
    // Use width and height to get the displayed size of the image
    canvas.width = image.width;
    canvas.height = image.height;

    const context = canvas.getContext('2d');
    context?.drawImage(image, 0, 0, image.width, image.height);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob!);
      }, 'image/jpeg');
    });
  }

  const onSubmit = async (event: any) => {
    
    event.preventDefault();
    const accessToken = await getAccessTokenSilently();
    setIsUploading(true); // Set uploading to true at the beginning
    let canvas = canvasRef.current;
    if (canvas && imageSrc) {
      // console.log("updatedValues" + getValues());
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // console.log(percentCompleted)
            setUploadProgress(percentCompleted);
            // console.log("Upload Progress: " + uploadProgress)
          }
        };
        let localUUID = uuidv4();
        const dirtyFormPush = new FormData();
        props.setComputedEggCount(computedEggCount ? computedEggCount : 0);
        canvasToBlob(canvas, 'image/jpeg').then(async (blob) => {
          if (blob) {
            props.setFileName(localUUID);
            console.log('UUID:' + localUUID + " | File Name: " + localUUID + "_analyzed.jpg");
            dirtyFormPush.append("file", blob, `${localUUID}_analyzed.jpg`);
            const res = await axios.post(
              `${BASE_URL}/files/`,
              dirtyFormPush,
              config
            ).then(res => {
              console.log("Resulting data" + res.data);
              if (res.status == 200) {

                console.log("Analysis: Status is " + res.status);
              }
            }).catch(e => {
              console.error(e + ":\n" + (e as AxiosError)?.response?.data);
              // console.log(dirtyFormPush);
              dirtyFormPush.forEach((value, key) => {
                console.log("key %s: value %s", key, value);
              });
            });

            // For original image
            const img = new Image();
            img.src = imageSrc;
            imageToBlob(img).then(async (blob) => {
              const originalFormPush = new FormData();
              originalFormPush.append("file", blob, `${localUUID}.jpg`);
              await axios.post(
                `${BASE_URL}/files/`,
                originalFormPush,
                config
              ).then(res => {
                // console.log("Original Image: Resulting data" + res.data);
                if (res.status == 200) {
                  console.log("Original Image: Status is " + res.status);
                  setImagesSubmitted(true);
                  setIsUploading(false); // Set uploading to false when the upload is complete
                }
              }).catch(e => {
                console.error(e + ":\n" + (e as AxiosError)?.response?.data);
                // console.log(originalFormPush)
                setIsUploading(false); // Set uploading to false if an error occurred
                originalFormPush.forEach((value, key) => {
                  console.log("key %s: value %s", key, value);
                })
              });
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  };


  useEffect(() => {
    if (imageSrc) {

      const ctx = canvasRef.current!.getContext('2d');
      canvasRef.current!.style.backgroundColor = "red";
      const image = new Image();
      image.src = imageSrc;
      image.onload = function () {
        canvasRef.current!.width = image.width;
        canvasRef.current!.height = image.height;
        ctx!.drawImage(image, 0, 0, canvasRef.current!.width, canvasRef.current!.height);
      };
      processImage();
    } else {
      console.error("YoloImage error: ", imageSrc)
    }
  }, [imageSrc])

  return (
    <div>
      <div className="Aedes-view">

        {loading.loading && <Loader>Loading model... {(loading.progress * 100).toFixed(2)}%</Loader>}
        <div className="header">
          <h1>Egg Counter</h1>
          <br />

        </div>

        <canvas ref={canvasRef} />
        {computedEggCount != null ? <h1>{"Egg Count: " + computedEggCount}</h1> : ""}
        <ButtonHandler imageSrcState={[imageSrc, setImageSrc]} canvasRef={canvasRef} cameraRef={undefined} videoRef={undefined} setComputedEggCount={setComputedEggCount} imagesSubmitted={imagesSubmitted} />
        {imageAnalyzed && (computedEggCount!=null) ? (imagesSubmitted ?
          <h1>Image Submitted</h1> :
          (isUploading ?
            <>
            <CircularProgressWithLabel value={uploadProgress} />
            <p>Uploading your images</p>
</> :
            <Button color="primary" variant="outlined" onClick={onSubmit} style={{ margin: "10px" }}>Upload Image</Button>)
        ) : ""}
      </div>
    </div>

  );
};

export default YoloImage;
