import Button from "@mui/material/Button";

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { Image } from "../models/Image";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import ButtonAppBar from "../components/ButtonAppBar";
import { BASE_URL } from "../constants/global";
import { useAuth0 } from "@auth0/auth0-react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import './ViewAede.css';
import axios from "axios";

export interface routePrams {
  id: string;
}
const ViewAedes: React.FC = () => {
  const { id } = useParams();
  const [image, setImage] = React.useState<Image>({
    id: 0,
    authorId: 0,
    authorIdentity: null,
    fileName: "",
    address: "",
    siteId: "",
    descriptorId: 0,
    weekId: 0,
    uniqueImageId: "",
    sampleName: "",
    setUpDate: "",
    collectionDate: "",
    pictureDate: "",
    manualEggCount: 0,
    species: "",
    hatchingDate: "",
    origin: "",
    type: "",
    heightPhoto: 0,
    stand: "",
    anglePhoto: 0,
    device: "",
    resolution: "",
    lightCondition: "",
    photoCondition: "",
    paperType: "",
    paperColor: "",
    paperCondition: "",
    waterType: "",
    trapType: "",
    deployTime: 0,
    computedEggCount: 0,
    eggMorphoId: 0,
    adultMorphoId: 0,
    rating: 0,
    note: "",
    clientInfo: "",
    timeStamp: null
  });
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [imageUrl, setImageUrl] = useState('');
  const [analyzedImageUrl, setAnalyzedImageUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        };

        const response = await fetch(`${BASE_URL}/v1/image/${id}`, config);
        const data = await response.json();
        setImage(data);
        document.title = `ACTT | ${data.uniqueImageId}`;

        // Fetching the first authenticated image
        const mainImageResponse = await fetch(`${BASE_URL}/files/${data.fileName}.jpg`, config);
        if (mainImageResponse.ok) {
          const mainImageBlob = await mainImageResponse.blob();
          const mainImageObjectURL = URL.createObjectURL(mainImageBlob);
          setImageUrl(mainImageObjectURL);
        } else {
          console.error('Failed to fetch main image');
        }

        // Fetching the second authenticated image
        const analyzedImageResponse = await fetch(`${BASE_URL}/files/${data.fileName}_analyzed.jpg`, config);
        if (analyzedImageResponse.ok) {
          const analyzedImageBlob = await analyzedImageResponse.blob();
          const analyzedImageObjectURL = URL.createObjectURL(analyzedImageBlob);
          setAnalyzedImageUrl(analyzedImageObjectURL);
        } else {
          console.error('Failed to fetch analyzed image');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const onDelete = async (event: any) => {
    event.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();
      if (accessToken) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const res = await axios.delete(`${BASE_URL}/v1/image/${image.id}`, config);

        if (res.status === 200) {
          console.log('Image deleted successfully');
          window.location.href = '/images'
        }
      } else {
        console.error('Bearer token not available');
        // Handle the case where the token is not available
      }
    } catch (e) {
      console.error(e);
      // Handle other errors related to API requests
    }
  };

  return (
    <div className="App">
      <ButtonAppBar />
      <h2>{image.uniqueImageId}</h2>

      <h1>
        {/* Status:{" "}
        {result.length > 0 ? (
          <Chip label="processed" color="success" />
        ) : (
          <Chip label="needs image" color="warning" />
        )} */}
        {/* {status.status === "processed" ? <Chip label={status.status} color="success" /> : <Chip label={status.status} color="warning" />}*/}
      </h1>
      {/* <h1>Image Details</h1> */}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "80vh" }}
      >
        <Grid item xs={3}>
          <Card sx={{ maxWidth: 500, padding: "20px" }}>
            <CardMedia>
              <img
                src={imageUrl}
                alt={"Image ID: " + image.uniqueImageId}
                style={{ maxHeight: "500px" }}
              />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {image.computedEggCount + " eggs detected"}
              </Typography>
              <Typography variant="body2" color="text.secondary" textAlign="left">
                <b>Descriptor id</b>: {image.descriptorId}
                <br />
                <b>Week id</b>: {image.weekId ? image.weekId : "N/A"} <br />
                <b>Unique Image id</b>: {image.uniqueImageId} <br />
                <b>Sample Name</b>:{" "}
                {image.sampleName ? image.sampleName : "not specified"} <br />
                <b>Address</b>:{" "}
                {image.address ? image.address : "not specified"} <br />
                <b>Setup Date</b>: {dayjs(image.setUpDate).format("MM-DD-YYYY")}{" "}
                <br />
                <b>Collection Date</b>:{" "}
                {dayjs(image.collectionDate).format("MM-DD-YYYY")} <br />
                <b>Picture Date</b>:{" "}
                {dayjs(image.pictureDate).format("MM-DD-YYYY")} <br />
                <b>Manual Egg Count</b>: {image.manualEggCount} <br />
                <b>Species</b>:{" "}
                {image.species ? image.species : "not specified"} <br />
                <b>Hatching Date</b>:{" "}
                {dayjs(image.hatchingDate).format("MM-DD-YYYY")} <br />
                <b>Origin</b>: {image.origin ? image.origin : "not specified"}{" "}
                <br />
                <b>Type</b>: {image.type ? image.type : "not specified"} <br />
                <b>Height Photo</b>:{" "}
                {image.heightPhoto ? image.heightPhoto : "not specified"} <br />
                <b>Stand</b>: {image.stand ? image.stand : "not specified"}{" "}
                <br />
                <b>Angle Photo</b>:{" "}
                {image.anglePhoto ? image.anglePhoto : "not specified"} <br />
                <b>Device</b>: {image.device ? image.device : "not specified"}{" "}
                <br />
                <b>Resolution</b>:{" "}
                {image.resolution ? image.resolution : "not specified"} <br />
                <b>Light Condition</b>:{" "}
                {image.lightCondition ? image.lightCondition : "not specified"}{" "}
                <br />
                <b>Photo Condition</b>:{" "}
                {image.photoCondition ? image.photoCondition : "not specified"}{" "}
                <br />
                <b>Paper Type</b>:{" "}
                {image.paperType ? image.paperType : "not specified"} <br />
                <b>Paper Color</b>:{" "}
                {image.paperColor ? image.paperColor : "not specified"} <br />
                <b>Paper Condition</b>:{" "}
                {image.paperCondition ? image.paperCondition : "not specified"}{" "}
                <br />
                <b>Water Type</b>:{" "}
                {image.waterType ? image.waterType : "not specified"} <br />
                <b>Trap Type</b>:{" "}
                {image.trapType ? image.trapType : "not specified"} <br />
                <b>Deploy Time</b>:{" "}
                {image.deployTime
                  ? `${image.deployTime} days`
                  : "not specified"}
                <br />
                <b>Note</b>: {image.note ? image.note : "not specified"}
                <br />
                <b>Rating</b>:{" "}
                {image.rating
                  ? Array.from({ length: 5 }, (_, index) => (
                    <span
                      key={index}
                      title={`${image.rating ?? 0} out of 5 stars`}
                      style={{ cursor: "default" }}
                    >
                      {index < (image.rating ?? 0) ? "\u2605" : "\u2606"}
                    </span>
                  ))
                  : "not specified"}
              </Typography>
            </CardContent>
            {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "50vh", paddingTop: "30px", paddingBottom: "30px" }}
      >
        <Grid item xs={3}>
          <Card sx={{ maxWidth: 500, padding: "20px" }}>
            <CardMedia>
              <img
                src={analyzedImageUrl}
                alt={"Image ID: " + image.uniqueImageId + " analyzed"}
                style={{ maxHeight: "500px" }}
              />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Results
              </Typography>
              <Typography variant="body2" color="text.secondary" textAlign="left">
                <b>Computed Egg Count</b>: {image.computedEggCount} <br />
                <b>Species</b>:{" "}
                {image.species ? image.species : "not specified"} <br />
                <b>Egg Morpho Id</b>:{" "}
                {image.eggMorphoId ? image.eggMorphoId : "not specified"} <br />
                <b>Adult Morpho Id</b>:{" "}
                {image.adultMorphoId ? image.adultMorphoId : "not specified"}{" "}
                <br />{" "}
              </Typography>
            </CardContent>
            {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
          </Card>
        </Grid>
      </Grid>
      <div className="danger-zone">
        <h3>Danger Zone</h3>
        <div className="action-buttons">
          <Button variant="outlined" color="warning" startIcon={<EditIcon />} component={Link} to={"/edit/"+image.id}>
            Edit
          </Button>
          <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenModal(true)}>
            Delete
          </Button>
        </div>
      </div>
      <div className="backListButton">
        <Button variant="outlined" color="success" component={Link} to="/images">
          Back to list
        </Button>
        </div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="delete-modal-title"
          aria-describedby="delete-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="delete-modal-title" variant="h6" component="h2">
              Are you sure you want to delete this image?
            </Typography>
            <h5>This cannot be undone</h5>
            <div className="modalButtons">
              <Button color="primary" onClick={() => setOpenModal(false)}>Cancel</Button>
              <Button color="error" variant="contained" onClick={onDelete}>Delete</Button>
            </div>
          </Box>
        </Modal>
    </div>
  );
};

export default ViewAedes;
