import React, { useRef, useState, useEffect } from "react";
import Webcam from 'react-webcam';
import './ViewCamera.css'; // Import the CSS file for styling

interface MobileCameraViewProps {
  onImageCapture: (imageData: string | null) => void;
}

export const MobileCameraView: React.FC<MobileCameraViewProps> = ({ onImageCapture }) => {
  const [isCapturing, setIsCapturing] = useState(true); // Start capturing immediately

  const webcamRef = useRef<Webcam | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    startCapture();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  const toggleCapture = () => {
    if (!isCapturing) {
      startCapture();
    } else {
      captureImage();
    }
  };

  const startCapture = () => {
    setIsCapturing(true);
  };

  const captureImage = () => {
    const webcamElement = webcamRef.current?.video;
    const canvas = canvasRef.current;
    if (webcamElement && canvas) {
      const video = webcamElement as HTMLVideoElement;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d')!.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageDataURL = canvas.toDataURL('image/jpeg'); // Convert canvas to image data URL
      onImageCapture(imageDataURL);
      setIsCapturing(false); // Reset capturing state
    } else {
      console.error('Video element or canvas not found.');
    }
  };

  return (
    <div className="ViewCamera">
      <div className="camera-container">
        <Webcam
          ref={webcamRef}
          className="camera"
          videoConstraints={{ facingMode: 'environment' }}
        />
        <button className={`capture-button circle ${isCapturing ? 'recording' : ''}`} onClick={toggleCapture}>
        </button>
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};
