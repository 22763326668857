import React, { useState, useRef, useEffect } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import algorithm, {Analysis} from './alg';

import 'react-image-crop/dist/ReactCrop.css'
import { Button, InputLabel, TextField } from '@mui/material'
// import { imgPreview } from './imgPreview'
import ButtonAppBar from './ButtonAppBar'
import { useNavigate, useParams } from 'react-router-dom'
import { Image } from "../models/Image"
import axios, { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { BASE_URL, UPSTREAM_URL } from '../constants/global'
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';


// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}
type fileNameProps =  {
  setFileName: (arg: string) => void,
  setComputedEggCount: (arg: number) => void
}
export const UploadImage: React.FC<fileNameProps> = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const virtualCanvasRef = useRef<HTMLCanvasElement>(null)
  const virtualImageRef = useRef<HTMLImageElement>(null)
  const analysisRef = useRef<Analysis | null>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>()
  const [imagesSubmitted, setImagesSubmitted] = useState<boolean>(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current &&
        virtualCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          virtualCanvasRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        )
        console.log(computeEggCount(analysisRef.current, completedCrop))
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(16 / 9)
      setCrop(centerAspectCrop(width, height, 16 / 9))
    }
  }
  const {
    register,
    setValue,
    handleSubmit, control, getValues,

    formState: { errors }
  } = useForm({
    defaultValues: {

    }
  });

  const onSubmit = async (event: any) => {
    event.preventDefault();
    // try {
    //   const config = {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       // 'Authorization': `Bearer ${context.token}`
    //     },
    //   };
    //   //TODO: add species, eggMorphoId. adultMorphoId
    //   const data = { 'imageId': id, "computedEggCount": computeEggCount(analysisRef.current, completedCrop!), "authorId": 101 };
    //   const res = await axios.post(
    //     `${BASE_URL}/v1/result/`,
    //     data,
    //     config
    //   ).then(res => {
    //     console.log("Resulting data" + res.data);
    //     if (res.status == 200) {
    //       console.log("Status is " + res.status);
    //       //If you want to redirect after posting...
    //       // props.history.push('/images/')
    //     }
    //   })
    //   .catch(e => {
    //     setError(true);
    //     console.error(e + ":\n" + (e as AxiosError)?.response?.data);
    //   });
    // } catch (e) {
    //   console.error(e);
    // }
    let canvas = previewCanvasRef.current
    let srcImage = virtualCanvasRef.current
    if (canvas && srcImage) {
      console.log("updatedValues" + getValues());
      //TODO: Post applciation JSON from form values along with the context token. Set the context ids and then post
      try {
        const accessToken = await getAccessTokenSilently();
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
        };
        // console.log("User ID: " + context.currentUser?.id)
        // setValue("id",string(context.currentUser?.id);
        // setValue("author", (context.currentUser?.id  ? (context.currentUser?.id) : 0))
        // console.log(getValues('imgSrc'));
        // setValue("setUpDate", setUpDate ? setUpDate : new Date());

        let localUUID = uuidv4();
        const dirtyFormPush = new FormData();
        let localEggCount = (computeEggCount(analysisRef.current, completedCrop!))
        props.setComputedEggCount(localEggCount ? localEggCount : 0)
        setImagesSubmitted(true);
         canvas.toBlob(
          async function (blob) {
            if (blob) {
              // console.log(blob)
              const previewUrl = URL.createObjectURL(blob);
              props.setFileName(localUUID)
              console.log('UUID:' + localUUID + " | File Name: " + localUUID)
              //File Method
              // var fileOfBlob = new File([blob], `${image.uniqueImageId}.jpg`);
              dirtyFormPush.append("file", blob, `${localUUID}_analyzed.jpg`);
              const res = await axios.post(
                `${BASE_URL}/files/`,
                dirtyFormPush,
                config
              ).then(res => {
                console.log("Resulting data" + res.data);
                if (res.status == 200) {
                  console.log("Status is " + res.status);
                }
              }).catch(e => {
                setError(true);
                console.error(e + ":\n" + (e as AxiosError)?.response?.data);
                console.log(dirtyFormPush)
                dirtyFormPush.forEach((value, key) => {
                  console.log("key %s: value %s", key, value);
                  })
              });

            }
          },
          'image/jpeg');
          const sourceFormPush = new FormData();
          srcImage.toBlob(
            async function (blob) {
              if (blob) {
                // console.log(blob)
                const previewUrl = URL.createObjectURL(blob);
                //File Method
                // var fileOfBlob = new File([blob], `${image.uniqueImageId}.jpg`);
                sourceFormPush.append("file", blob, `${localUUID}.jpg`);
                const res = await axios.post(
                  `${BASE_URL}/files/`,
                  sourceFormPush,
                  config
                ).then(res => {
                  console.log("Resulting data" + res.data);
                  if (res.status == 200) {
                    console.log("Status is " + res.status);
                    console.log(sourceFormPush)
                    sourceFormPush.forEach((value, key) => {
                      console.log("key %s: value %s", key, value);
                      })
                  }
                  // navigate('/image/' + id);
                }).catch(e => {
                  setError(true);
                  console.error(e + ":\n" + (e as AxiosError)?.response?.data);
                  console.log(sourceFormPush)
                  sourceFormPush.forEach((value, key) => {
                    console.log("key %s: value %s", key, value);
                    })
                });
  
              }
            },
            'image/jpeg');
      } catch (e) {
        console.error(e);
      }
    }
  };
  // const { id } = useParams()

  const renderBoxes = (a: Analysis) => {
    if (virtualCanvasRef.current != null) {
      virtualCanvasRef.current.width = a.imageData.width;
      virtualCanvasRef.current.height = a.imageData.height;
      const ctx = virtualCanvasRef.current.getContext("2d");
      ctx!.drawImage(virtualImageRef!.current!, 0, 0);
      for (let i of a.instanceData.individualEggs) {
          ctx!.beginPath();
          ctx!.lineWidth = 0;
          ctx!.strokeStyle = "rgb(0, 255, 0)";
          ctx!.rect(i.x - 1, i.y - 1, i.width + 3, i.height + 3);
          ctx!.stroke();
      }
      for (let i of a.instanceData.clusters) {
          ctx!.beginPath();
          ctx!.lineWidth = 1;
          ctx!.strokeStyle = "rgb(255, 0, 0)";
          ctx!.rect(i.x - 1, i.y - 1, i.width + 3, i.height + 3);
          ctx!.stroke();
      }
    }
  }

  const computeEggCount = function(a: Analysis | null, crop: PixelCrop) {
    if (a != undefined && imgRef.current != undefined) {
      let count = 0;
      const cropX = crop.x * imgRef.current.naturalWidth / imgRef.current.width
      const cropY = crop.y * imgRef.current.naturalHeight / imgRef.current.height
      const cropWidth = crop.width * imgRef.current.naturalWidth / imgRef.current.width
      const cropHeight = crop.height * imgRef.current.naturalHeight / imgRef.current.height
      for (let i of a.instanceData.individualEggs) {
        if (i.x+i.width>cropX && i.x<cropX+cropWidth && i.y+i.height>cropY && i.y<cropY+cropHeight) {
          count += 1;
        }
      }
      for (let i of a.instanceData.clusters) {
        if (i.x+i.width>cropX && i.x<cropX+cropWidth && i.y+i.height>cropY && i.y<cropY+cropHeight) {
          count += i.estimatedEggCount;
        }
      }
      return count;
    }
  }

  return (

<>
      <div className="Crop-Controls">
        <Button style={{ margin: "10px" }}
          variant="contained"
          component="label"
        >
          Upload File
          <input type="file" accept="image/*" hidden onChange={onSelectFile} />
        </Button>
        {Boolean(!imgSrc) && (
          <div>
            <h2>Upload an image to get started</h2>
          </div>
        )}
      </div>
      {Boolean(imgSrc) && (
        <div>
          <div>
            <InputLabel htmlFor="scale-input">Scale: </InputLabel>
            <TextField
              id="scale-input"
              type="number"
              inputProps={{
                maxLength: 10,
                step: ".1"
              }}
              value={scale}
              disabled={!imgSrc}
              onChange={(e) => setScale(Number(parseFloat(e.target.value).toFixed(1)))}
            />
          </div>
          <div>
            <InputLabel htmlFor="rotate-input">Rotate: </InputLabel>
            <TextField
              id="rotate-input"
              type="number"
              value={rotate}
              disabled={!imgSrc}
              onChange={(e) =>
                setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
              }
            />
          </div>
          <div>
            <Button variant="outlined" onClick={handleToggleAspectClick} style={{ margin: "10px" }}>Toggle aspect {aspect ? 'off' : 'on'}</Button>
          </div>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: '500px', maxWidth: '500px' }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <img //virtual image at full resolution used for computation
            ref={virtualImageRef}
            src={imgSrc} 
            style={{display: 'none'}}
            onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
              algorithm(e.currentTarget).then(
                function(result) {
                  renderBoxes(result)
                  analysisRef.current = result;
                }
              )
            }}
          />
          <canvas //virtual canvas at full resolution used for drawing
            ref={virtualCanvasRef}
            style={{display: 'none'}}
          />
        </div>
      )}
      <div>
        {Boolean(completedCrop) && (
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop?.width,
                height: completedCrop?.height,
              }}
            />
            <h1>Egg count: {computeEggCount(analysisRef.current, completedCrop!) ? <span style={{color: "green"}}>{computeEggCount(analysisRef.current, completedCrop!)}</span> : <span style={{color: "red"}}>No eggs detected</span>}</h1>
                        <div>
              {/* <Button color="success" variant="outlined" href={"/image/"+image.id} style={{margin: "10px"}}>Upload</Button> */}
              {/* <Button color="success" variant="outlined" onClick={} style={{ margin: "10px" }}></Button> */}
              {imagesSubmitted ? <h1>Image Submitted</h1>: 
              <Button color="primary" variant="outlined" onClick={onSubmit} style={{ margin: "10px" }}>Upload Image</Button>}
            </div>
          </div>
        )}
      </div>
      </>
  )

}
export default UploadImage;