import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

function SurveyButton() {
    const openInNewTab = (url: string | URL | undefined) => {
        window.open(url, "_blank", "noreferrer");
      };
      return(
    <Button variant="outlined" color="inherit" style={{marginBottom: 20}}onClick={() => {openInNewTab("https://redcap.healthsciencessc.org/surveys/?s=9PDM87C7T9P88DRR")}}>Survey</Button>
    )

}

export default SurveyButton;