import React, { useState, useEffect, useRef } from "react";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl"; // set backend to webgl
import Loader from "../components/loader";
import ButtonHandler from "../components/btn-handler";
import { detectImage } from "../utils/detect";
import "../styles/yolo.css";
import ButtonAppBar from "../components/ButtonAppBar";
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form'
import { toBlob } from "../components/imgPreview"
import { Button, LinearProgress } from "@mui/material";
import { BASE_URL } from "../constants/global";
import axios, { AxiosError } from "axios";
import { model } from "@tensorflow/tfjs";

import { CircularProgressWithLabel } from "./CircularProgressWithLabel";


export const YoloImageNoSubmit: React.FC = () => {
    const [imagesSubmitted, setImagesSubmitted] = useState<boolean>(false);
    const [imageAnalyzed, setImageAnalyzed] = useState<boolean>(false);
    const [computedEggCount, setComputedEggCount] = useState<number>();

    const [loading, setLoading] = useState({ loading: true, progress: 0 }); // loading state
    const [model, setModel] = useState({
        net: null,
        inputShape: [1, 0, 0, 3],
    }); // init model & input shape
    const [imageSrc, setImageSrc] = useState(null);

    // references
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    // model configs
    const modelName = "yolov5n";
    const classThreshold = 0.2;

    const processImage = async () => {
        let eggCount = await detectImage(imageSrc!, model as any, classThreshold, canvasRef.current!);
        setComputedEggCount(eggCount);
        setImageAnalyzed(true);
    }
    useEffect(() => {
        tf.ready().then(async () => {
            const yolov5 = await tf.loadGraphModel(
                `../model.json`,
                {
                    onProgress: (fractions) => {
                        console.log(fractions);
                        setLoading({ loading: true, progress: fractions }); // set loading fractions
                    },
                }
            ); // load model

            // warming up model
            const dummyInput = tf.ones(yolov5.inputs[0].shape!);
            const warmupResult = await yolov5.executeAsync(dummyInput);
            tf.dispose(warmupResult); // cleanup memory
            tf.dispose(dummyInput); // cleanup memory

            setLoading({ loading: false, progress: 1 });
            setModel({
                net: yolov5 as any,
                inputShape: yolov5.inputs[0].shape!,
            }); // set model & input shape
        });
    }, []);
    const {
        getValues } = useForm({
            defaultValues: {

            }
        });



    useEffect(() => {
        if (imageSrc) {
            const ctx = canvasRef.current!.getContext('2d');
            const image = new Image();
            image.src = imageSrc;

            image.onload = function () {
                canvasRef.current!.width = image.width;
                canvasRef.current!.height = image.height;
                ctx!.drawImage(image, 0, 0, canvasRef.current!.width, canvasRef.current!.height);
                processImage();
            };
        }
    }, [imageSrc])

    return (
        <div>
            <div className="Aedes-view">

                {loading.loading && <Loader>Loading model... {(loading.progress * 100).toFixed(2)}%</Loader>}
                <div className="header">
                    <h1>Egg Counter Playground</h1>
                    <br />
                </div>

                <canvas ref={canvasRef} />
                {computedEggCount != null ? <h1>{"Egg Count: " + computedEggCount}</h1> : ""}
                <ButtonHandler imageSrcState={[imageSrc, setImageSrc]} canvasRef={canvasRef} cameraRef={undefined} videoRef={undefined} setComputedEggCount={setComputedEggCount} imagesSubmitted={imagesSubmitted} />
            </div>
        </div>

    );
};

export default YoloImageNoSubmit;
